import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

class ProjectsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slash: ''
    }
}
componentDidMount() {
  if(this.props.data.link) {
  if(this.props.data.link.post_type == 'post') {
    this.setState({
      slash: 'wiadomosci'
    })
  }
  if(this.props.data.link.post_type == 'portfolio') {
    this.setState({
      slash: 'porfolio'
    })
  }
}
}
render() {
  let link;

  if(!this.props.data.choose_link) {
    link = <Link to={'/' + this.state.slash  + '/'+ this.props.data.link.post_name} className="btn">Dowiedz się więcej</Link>
  }
  else {
    link = <a href={this.props.data.link_outside} target="_blank" className="btn">Dowiedz sie więcej</a>
  }

  return(
    <div className="join-us-wrap">
    <div className="text">
      <div className="border-wrap">
        <div className="sneaky" dangerouslySetInnerHTML={{__html: this.props.data.text}}></div>
        {link}
      </div>
    </div>
    <div className="image">
    {this.props.data.image
      && (
        <Img fixed={ this.props.data.image.localFile.childImageSharp.fixed } alt={ this.props.data.image.alt_text } />
      )
    }
    </div>
  </div>
  )
}
}

export default ProjectsItem;