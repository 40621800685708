import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from '../components/portfolio-banner/PortfolioBanner'
import PortfolioHeader from '../components/portfolio-header/PortfolioHeader'
import Projects from '../components/projects/Projects'
import DownloadSection from '../components/download-section/DownloadSection'
import SEO from '../components/seo'

class PostTemplate extends Component {
  constructor(props) {
    super(props)
  }

  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-245px'
  }

  componentDidMount() {
    this.setFooterMargin()
  }

  render() {
    const currentPage = this.props.data.wordpressPage
    const homepage = this.props.data.wordpressSiteMetadata.home

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.featured_media} />
        <div className="take-up design">
          <PortfolioHeader
            content={currentPage.content}
            city={currentPage.acf.small_title}
            title={currentPage.acf.title}
          />
          <Projects items={currentPage.acf.news} />
          <DownloadSection data={currentPage.acf} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
              srcSet
            }
          }
        }
      }
      acf {
        title
        small_title
        news {
          choose_link
          link_outside
          link {
            wordpress_id
            post_title
            post_type
            post_content
            post_name
          }
          image {
            localFile {
              childImageSharp {
                fixed(width: 750, height: 423, quality: 95) {
                  src
                }
              }
            }
          }
          link_outside
          text
        }
        header
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                src
              }
            }
          }
        }
        file {
          guid {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    wordpressSiteMetadata {
      home
    }
  }
`
