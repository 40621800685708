import React, { Component } from 'react';
import ProjectsItem from './ProjectsItem';

export default (props) => (
  <section className="join-us">
  <div className="container">
      <div className="row">
      {props.items.map(item => (
      <ProjectsItem data={ item} />
      ))}
      </div>
  </div>
  </section>
)
 