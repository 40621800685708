import React, { Component } from "react";
import Img from 'gatsby-image';

class PortfolioBanner extends Component {
    constructor(props) {
        super(props);
    }
     
    render () {
        const RenderCaption = props => {
            if(props.data.title) {
                return (
                    <div class="caption">
                    <h4> { props.data.city } </h4>
                    <h3> { props.data.title } </h3>
                    </div>
                );
            } else {
                return null;
            }
        }
        return (
            <div className="banner">
            { this.props.data && 
                <Img fluid={ this.props.data.localFile.childImageSharp.fluid } alt={this.props.city + ' - ' + this.props.title} />
            }
            <RenderCaption data={this.props} />
            </div>
        );
    }
}

export default PortfolioBanner