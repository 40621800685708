import React from 'react';
export default (props) => (
    <div className="container about-intro">
        <div className="row">
            <div className="col-12">
                { props.city &&
                    <h5 dangerouslySetInnerHTML={{ __html:props.city.replace(/<(?:.|\n)*?>/gm, '') }}></h5>
                }
                <h3>{ props.title }</h3>
                {props.content && 
                <span dangerouslySetInnerHTML={{__html:props.content}}></span>}
            </div>
            <div class="col-12">
                <hr />
            </div>
        </div>
    </div>
);