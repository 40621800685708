import React from 'react';
import Img from 'gatsby-image';

export default (props) => (
    <div class="container quick-contact">
        <div class="row">
            <div class="col-lg-6">
                <h3>{ props.data.header }</h3>
                { props.data.file && 
                    <a href={ props.data.file[0].guid.localFile.publicURL } download class="btn">Pobierz katalog</a>
                }
            </div>
            <div class="col-lg-6">
                {props.data.image && 
                    <img src={ props.data.image.localFile.childImageSharp.fluid.src } alt={ props.data.image.alt_text } />
                }
            </div> 
            <div class="col">
                <hr />
            </div>
        </div>
    </div>
);